<template>
  <div>
    <TeamDirectMessageModal />
  <div class="team-details-wrap-top" v-if="selectedRoomTeam">
    <div class="team-details-top">
      <div class="data-info">
        <div class="image-team position-relative">
          <div class="users-notification" v-if="imgLoader == 1">
                <img src="../../assets/loader.gif" alt="" />
              </div>
          <img
            @load="onImageLoad"
            v-if="selectedRoomTeam.teamAvatar == ''"
            src="../../assets/images/team-avatar.png"
            alt=""
          />
          <img
            @load="onImageLoad"
            v-if="selectedRoomTeam.teamAvatar != ''"
            :src="this.$groupAvatarStorageURL + selectedRoomTeam.teamAvatar"
            alt=""
          />
        </div>
        <div>
          <div class="name-team">{{ selectedRoomTeam.teamName }}</div>
          <span class="type-team" v-if="selectedRoomTeam.teamType == 'sports'"
            >{{ selectedRoomTeam.sportType }}
            {{ selectedRoomTeam.ageGroup }}</span
          >
        </div>
      </div>
      <div class="play-options">
        <play :teamId="selectedRoomTeam.id" />
        <router-link
            class="nav-item nav-link"
            exact
            to="/teams"
          >
          <button class="msg-btn btn btn-primary"  v-if="currentIsFan != '1'">
          <img src="../../assets/images/message-white.png" alt="" />
          <span>Message Team</span>
        </button>
          </router-link>
        <button v-if="currentIsAdmin == '1'" class="team-options btn btn-secondary" @click="showHideInviteOptions()">
          <img src="../../assets/images/add-teammates.png" alt="" />
          <span>Invite to Team</span>
        </button>
        <button
          v-if="currentIsAdmin == '1'"
          class="team-options btn btn-secondary"
          :class="{ isActive: isActive }"
          @click="showHideTeamOptions()"
        >
          <img src="../../assets/images/team-options.png" alt="" />
          <span>Team Options</span>
        </button>
      </div>
    </div>

    <div v-if="teamOptions && selectedRoomTeam">
      <TeamOptions
        :selectedRoomTeam="selectedRoomTeam"
        :currentUserId="currentUserId"
        :currentIsAdmin="currentIsAdmin"
        :currentIsFan="currentIsFan"
        :currentTeamIsActive="currentTeamIsActive"
      />
    </div>
    <InviteToTeamModal :currentUserId="currentUserId" :selectedRoomTeam="selectedRoomTeam" />
  </div>
  </div>
</template>

<script>
import TeamOptions from "../Teams/ChatRight/ChatSettingComponents/TeamOptions.vue";
import InviteToTeamModal from "../Teams/ChatRight/ChatSettingComponents/InviteToTeamModal.vue";
import Play from "./Play.vue";
import TeamDirectMessageModal from './TeamDirectMessageModal.vue';
export default {
  components: { Play, TeamOptions, InviteToTeamModal, TeamDirectMessageModal },
  name: "TeamDetailsTop",
  data() {
    return {
      inviteOptions: false,
      teamOptions: false,
      isActive: false,
      imgLoader: 1,
    };
  },

  props: {
    selectedRoomTeam: {
      type: Object,
    },
    currentUserId: {
      type: String,
    },
    currentIsAdmin: {
      type: String,
    },
    currentTeamIsActive: {
      type: Number,
    },
    currentIsFan: {
      type: String,
    },
  },
  methods: {
    onImageLoad() {
      this.imgLoader = 0;
    },
    showHideInviteOptions() {
      this.$root.$emit("openInviteToTeamModal");
    },
    showHideTeamOptions() {
      this.teamOptions = !this.teamOptions;
      this.isActive = !this.isActive;
    },
    openTeamMessageModal() {
      this.$root.$emit("fetchTeamDirectMessageModal", this.selectedRoomTeam, this.currentUserId);
    },
  },
};
</script>

<style>
.isActive {
  background: #188ef5 !important;
  color: #ffffff !important;
}

.isActive img {
  filter: brightness(0) invert(1) !important;
}
</style>